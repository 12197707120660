<template>
  <b-modal
    no-close-on-backdrop
    no-close-on-esc
    id="novo-cliente"
    centered
    size="lg"
    hide-footer
    cancel-variant="default"
    @hide="clearDados"
  >
    <template slot="modal-header">
      <h4 class="modal-title">Dados do cliente</h4>
      <a class="btn-close" @click="$bvModal.hide('novo-cliente')"></a>
    </template>
    <form @submit.prevent="onFormSubmit">
      <div class="row">
        <div class="col-12 mb-2">
          <label class="form-label">Nome:</label>
          <input class="form-control" required type="text" v-model="nome" />
        </div>
        <div class="w-100 d-flex justify-content-between mt-3">
          <a
            class="me-2 rounded btn btn-danger btn-sm"
            @click="$bvModal.hide('novo-cliente')"
          >
            <i class="fa fa-close me-2" />Fechar
          </a>
          <button
            :disabled="saving"
            type="submit"
            class="d-flex align-items-center me-2 rounded btn btn-warning btn-sm"
          >
            <b-spinner v-if="saving" small class="me-2" />
            <i class="fa fa-save me-2" />Salvar
          </button>
        </div>
      </div>
    </form>
    <notifications
      group="top-right"
      :duration="5000"
      :width="500"
      animation-name="v-fade-left"
      position="top left"
    />
  </b-modal>
</template>
<script>
import axios from 'axios'

export default {
  name: 'ModalCliente',
  props: ['loadItens'],
  data() {
    return {
      isLoad: false,
      saving: false,
      nome: ''
    }
  },
  methods: {
    clearDados() {
      this.nome = ''
    },
    async onFormSubmit() {
      this.saving = true
      let data = {
        nome: this.nome
      }

      let url = 'cliente'
      let method = 'post'

      axios({ method, url, data })
        .then(res => {
          return res.data
        })
        .then(res => {
          this.$notify({
            group: 'top-right',
            title: `Cliente criado com sucesso!`,
            text: res.mensagem,
            type: 'success'
          })
          this.fecharModal()
          this.loadItens()
        })
      this.saving = false
    },
    fecharModal() {
      this.$bvModal.hide('novo-cliente')
    }
  }
}
//mostrar paginas varias if multivias true
</script>

<style scoped></style>
